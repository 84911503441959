import $ from 'jquery'
import 'slick-carousel';

$(document).ready( initTickerSlider )
if( window.acf ){
  window.acf.addAction( 'render_block_preview/type=ticker-slider', initTickerSlider )
}

function initTickerSlider(){
  const $sliders = $('.js-ticker-slider:not(.slick-initialized)')
  if ( $sliders.length ) {
    $sliders.each(function () {
      let $el = $(this);
      $el.slick({
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        variableWidth: true,
        draggable: false,
        pauseOnFocus: false,
        pauseOnHover: false
      }).on("beforeChange", function(e, slick) {
          var width = $('.js-ticker-slider .slick-current').outerWidth();
          var speed = (width*3000)/100;
          $('.js-ticker-slider').slick("setOption", "speed", speed);
      });
    });
  }
}
