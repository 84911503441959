import $ from 'jquery'

LotDropDown()

function LotDropDown() {

  let blogDropDownBtn = $('.js-blog-cat-btn');
  let blogDropDownMenu = $('.js-blog-subnav-menu');
  let blogDropDownItem = $('.js-blog-subnav-menu > li > a');

  let pathSlug = new URL(window.location.href).pathname
  pathSlug = pathSlug.split('/').join('');
  // console.log(pathSlug)
  blogDropDownItem.removeClass('active')
  
  $('.js-blog-subnav-menu > li > a').each(function(){
    if($(this).attr('data-slug') === pathSlug) {
      $(this).addClass('active')
      console.log($(this))
      let blogCatText = $(this).text();
      $('.js-blog-cat-btn > span').text(blogCatText);
    }  
  })

  blogDropDownBtn.click(function (event) {
      // console.log($(this))
      event.preventDefault();
      $(this).toggleClass('active');
      $(this).next().toggleClass('active');
      $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'false' ? 'true' : 'false');
  });

  blogDropDownItem.click(function (event) {
      $(event.currentTarget).parent().parent().toggleClass('active');
      $(event.currentTarget).parent().parent().prev().toggleClass('active');
      let blogCatText = $(event.currentTarget).text();
      
      $('.js-blog-cat-btn > span').text(blogCatText);

      $(event.currentTarget).parent().prev().attr('aria-expanded', $(event.currentTarget).parent().prev().attr('aria-expanded') === 'false' ? 'true' : 'false');
  });

  $(document).on('keydown', function (event) {
      if (blogDropDownBtn.hasClass('active') && (event.key == "Escape")) {
        blogDropDownBtn.attr('aria-expanded', 'false')
        blogDropDownBtn.removeClass('active')
        blogDropDownMenu.removeClass('active')
      }
  })

  $(document).on('focusin click', function (event) {
      var $target = $(event.target);
      if (!$target.closest('.blog__subnav').length) {
        if (blogDropDownBtn.hasClass('active')) {
          blogDropDownBtn.attr('aria-expanded', 'false')
          blogDropDownBtn.removeClass('active')
          blogDropDownMenu.removeClass('active')
        }
      }
  })

  blogDropDownItem.keypress(function (event) {
      var key = event.which;
      var $target = $(event.target);
      if(key == 13) { // Enter key
        blogDropDownItem.click()
        if (!$target.closest('.blog__subnav').length) {
          if (blogDropDownBtn.hasClass('active')) {
            blogDropDownBtn.attr('aria-expanded', 'false')
            blogDropDownBtn.removeClass('active')
            blogDropDownMenu.removeClass('active')
          }
        }
      }
  })  
}
