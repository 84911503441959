import $ from 'jquery'
import { getCookie, setCookie } from '/lib/cookie'

$(function () {
  if ($('.page-template-pdp-lowes').length) {
    return
  }

  if (!getCookie('mg-announcement-banner')) {
    const bfcd = document.getElementById('bfcd')
    const $banner = $('.js-announcement-banner')
    setCookie('announceBannerActive', 'true', 1)
    let $height = bfcd ? bfcd.clientHeight + $banner.height() + 20 : $banner.height() + 20
    $('.main').css('margin-top', $height)
    $banner.slideDown()
    $('.js-banner-close').click(function () {
      const bfcd = document.getElementById('bfcd')
      $banner.slideUp()
      setCookie('mg-announcement-banner', 'true', 1)
      setCookie('announceBannerActive', 'false', 1)
      $('.main').css('margin-top', bfcd ? `${bfcd.clientHeight}px` : '0px')
    })
  }
})
