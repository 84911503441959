const endDate = new Date(Date.UTC(2024, 10, 23, 8, 0, 0))
const baseCountdownClass = "bfcd-counter-value-"
const daySelector = `${baseCountdownClass}days-digit-1`
const daySelectorB = `${baseCountdownClass}days-digit-2`
const hourSelector = `${baseCountdownClass}hours-digit-1`
const hourSelectorB = `${baseCountdownClass}hours-digit-2`
const minuteSelector = `${baseCountdownClass}minutes-digit-1`
const minuteSelectorB = `${baseCountdownClass}minutes-digit-2`
const secondSelector = `${baseCountdownClass}seconds-digit-1`
const secondSelectorB = `${baseCountdownClass}seconds-digit-2`

const updateCountdown = () => {
  const now = new Date()
  const distance = endDate - now

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  const dayElements = document.getElementsByClassName(daySelector)
  const dayElementsB = document.getElementsByClassName(daySelectorB)
  const hourElements = document.getElementsByClassName(hourSelector)
  const hourElementsB = document.getElementsByClassName(hourSelectorB)
  const minuteElements = document.getElementsByClassName(minuteSelector)
  const minuteElementsB = document.getElementsByClassName(minuteSelectorB)
  const secondElements = document.getElementsByClassName(secondSelector)
  const secondElementsB = document.getElementsByClassName(secondSelectorB)

  if (dayElements.length > 0) {
    let paddedDays = days.toString().padStart(2, '0')
    for (let i = 0; i < dayElements.length; i++) {
      dayElements[i].innerHTML = paddedDays[0]
      dayElementsB[i].innerHTML = paddedDays[1]
    }
  }

  if (hourElements.length > 0) {
    let paddedHours = hours.toString().padStart(2, '0')
    for (let i = 0; i < hourElements.length; i++) {
      hourElements[i].innerHTML = paddedHours[0]
      hourElementsB[i].innerHTML = paddedHours[1]
    }
  }

  if (minuteElements.length > 0) {
    let paddedMinutes = minutes.toString().padStart(2, '0')
    for (let i = 0; i < minuteElements.length; i++) {
      minuteElements[i].innerHTML = paddedMinutes[0]
      minuteElementsB[i].innerHTML = paddedMinutes[1]
    }
  }

  if (secondElements.length > 0) {
    let paddedSeconds = seconds.toString().padStart(2, '0')
    for (let i = 0; i < secondElements.length; i++) {
      secondElements[i].innerHTML = paddedSeconds[0]
      secondElementsB[i].innerHTML = paddedSeconds[1]
    }
  }
}

// Initialize the values so there is not a 1 second delay
updateCountdown();

setInterval(updateCountdown, 1000)
