import $ from 'jquery'
import 'slick-carousel';

$(document).ready( initTextLogoSlider )
if( window.acf ){
  window.acf.addAction( 'render_block_preview/type=image-content', initTextLogoSlider )
}

function initTextLogoSlider(){
  const $sliders = $('.js-text-logo-slider:not(.slick-initialized)')
  if ( $sliders.length ) {
    $sliders.each(function () {
      let $el = $(this);
      let speed = $el.data('speed') * 1000;
      $el.slick({
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: speed,
        speed: 600,
      });
    });
  }
}