; (() => {
  const toggleSiblings =
    document.querySelector('.yz-accordion__accordion')?.dataset
      .toggleSiblings === 'true'

  document.querySelectorAll('.yz-accordion__title').forEach((el) => {
    el.addEventListener('click', (e) => {
      const optEl = e.currentTarget?.parentElement

      if (toggleSiblings && optEl?.parentNode.children) {
        Array.from(optEl.parentNode.children)
          .filter((sib) => sib !== optEl)
          .forEach((sib) => sib.classList.remove('yz-accordion__item--open'))
      }

      if (optEl) {
        optEl.classList.toggle('yz-accordion__item--open')
      }
    })
  })
})()
