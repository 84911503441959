; (() => {
  const delay = 1500

  if (!document.querySelector('.bfb__right .images')) {
    return
  }

  setInterval(() => {
    const current = document.querySelector('.bfb__right .images .visible')
    let next = current.nextElementSibling

    if (!next) {
      next = document.querySelector('.bfb__right .images img:first-of-type')
    }

    current.classList.toggle('visible')
    next.classList.toggle('visible')
  }, delay)
})()
