import $ from 'jquery'
import 'slick-carousel'

$('.reviews-carousel__carousel').slick({
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  arrows: false,
  slidesToShow: 1,
  centerMode: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
})
