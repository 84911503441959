import $ from 'jquery'
import 'slick-carousel';

$(document).ready( initCardsSliders )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=cards', initCardsSliders)
}

$(window).on("load resize", function (e) {
  $('.cards__card-image-wrap').each(function () {
    let wrapheight = $(this).height();
    $(this).find('img').css('height', wrapheight);
  });
});

function initCardsSliders(){
  if ($('.js-card-slider:not(.slick-initialized)').length) {
    $('.js-card-slider:not(.slick-initialized)').each(function () {
      let slides = $(this).data('slides');
      $(this).slick({
        arrows: true,
        prevArrow: '<button class="slick-prev button black"><span class="u-sr-only">Previous</span><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.29854 13.7837C7.66256 14.1018 8.21531 14.0647 8.5335 13.701C8.85137 13.337 8.8142 12.7842 8.4505 12.466L2.20331 7.00006L8.45083 1.53376C8.81484 1.21557 8.85169 0.662821 8.53382 0.299128C8.21563 -0.0648861 7.66288 -0.102057 7.29886 0.216135L0.29897 6.34124C0.108952 6.50723 4.44873e-06 6.74755 4.42665e-06 7.00006C4.40458e-06 7.25256 0.108952 7.49256 0.29897 7.65887L7.29854 13.7837Z" fill="white"/></svg></button>',
        nextArrow: '<button class="slick-next button black"><span class="u-sr-only">Next</span><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.70146 0.216343C1.33744 -0.101849 0.784695 -0.0646781 0.466503 0.299015C0.148632 0.663029 0.185802 1.21578 0.549496 1.53397L6.79669 6.99994L0.549173 12.4662C0.185159 12.7844 0.148309 13.3372 0.46618 13.7009C0.784372 14.0649 1.33712 14.1021 1.70114 13.7839L8.70103 7.65876C8.89105 7.49277 8.99999 7.25245 8.99999 6.99994C9 6.74744 8.89105 6.50744 8.70103 6.34113L1.70146 0.216343Z" fill="white"/></svg></button>',
        dots: true,
        slidesToShow: slides,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    });
  }
  if ($('.js-card-slider-mobile').length) {
    $(window).on('load', () => {
        const element = $('.js-card-slider-mobile');
        const mediaQuery = window.matchMedia('(max-width: 960px)');

        const handleSwitchSlick = ((e) => {
            if (e.matches) {
                element.slick({
                  arrows: true,
                  prevArrow: '<button class="slick-prev button black"><span class="u-sr-only">Previous</span><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.29854 13.7837C7.66256 14.1018 8.21531 14.0647 8.5335 13.701C8.85137 13.337 8.8142 12.7842 8.4505 12.466L2.20331 7.00006L8.45083 1.53376C8.81484 1.21557 8.85169 0.662821 8.53382 0.299128C8.21563 -0.0648861 7.66288 -0.102057 7.29886 0.216135L0.29897 6.34124C0.108952 6.50723 4.44873e-06 6.74755 4.42665e-06 7.00006C4.40458e-06 7.25256 0.108952 7.49256 0.29897 7.65887L7.29854 13.7837Z" fill="white"/></svg></button>',
                  nextArrow: '<button class="slick-next button black"><span class="u-sr-only">Next</span><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.70146 0.216343C1.33744 -0.101849 0.784695 -0.0646781 0.466503 0.299015C0.148632 0.663029 0.185802 1.21578 0.549496 1.53397L6.79669 6.99994L0.549173 12.4662C0.185159 12.7844 0.148309 13.3372 0.46618 13.7009C0.784372 14.0649 1.33712 14.1021 1.70114 13.7839L8.70103 7.65876C8.89105 7.49277 8.99999 7.25245 8.99999 6.99994C9 6.74744 8.89105 6.50744 8.70103 6.34113L1.70146 0.216343Z" fill="white"/></svg></button>',
                  dots: true,
                });
            } else if (element.hasClass('slick-initialized')) {
                element.slick('unslick');
            }
        });

        mediaQuery.addListener(handleSwitchSlick);
        handleSwitchSlick(mediaQuery);
    });
  }
}

